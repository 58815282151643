<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import { Link, usePage, router } from '@inertiajs/vue3';
import { initFlowbite } from 'flowbite';
import { Drawer } from 'flowbite';
import { onMounted, onUnmounted } from 'vue';


defineProps({
    canLogin: {
        type: Boolean,
    },
    canRegister: {
        type: Boolean,
    },
});

let drawer = '';
const page = usePage();

function toggleMobileMenu() {
    drawer.toggle();
}

const logout = () => {
    router.post(route('logout'));
};

onMounted(() => {
    initFlowbite();

    const options = {
        placement: 'right',
        backdrop: true,
        bodyScrolling: true,
        edge: false,
        edgeOffset: '',
        backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30',
    };

    drawer = new Drawer(document.getElementById('default-sidebar'), options);
});

onUnmounted(() => {
    drawer.hide();
});

</script>

<template>
    <aside id="default-sidebar" class="bg-slate-800 fixed z-40 w-72 h-screen transition-transform -translate-x-full right-0 top-0 translate-x-full" aria-label="Sidebar">
        <ApplicationLogo
            class="mt-5 max-h-[54px] h-full w-full"
            :href="route('dashboard')"
        />
        <!-- Navigation Links -->
        <PerfectScrollbar class="h-full px-3 py-4 justify-start flex flex-col md:pe-4 h-[calc(100vh-107px)]">
            <ul class="w-full mt-5 flex flex-col gap-4 px-3">
                <li>
                    <Link class="text-white font-russo py-1 w-full inline-block" :href="route('book')">Резервирай</Link>
                </li>
                <li>
                    <Link class="text-white font-russo py-1 w-full inline-block" :href="route('gallery')">Галерия</Link>
                </li>
                <li>
                    <Link class="text-white font-russo py-1 w-full inline-block" :href="route('faq')">Често задавани въпроси</Link>
                </li>
                <li>
                    <Link class="text-white font-russo py-1 w-full inline-block" :href="route('leaderboards')">Класации</Link>
                </li>
                <li>
                    <Link class="text-white font-russo py-1 w-full inline-block" :href="route('contacts')">Контакти</Link>
                </li>
                <li v-if="$page.props.auth.user" class="mt-4">
                    <Link class="glow-on-hover text-white font-russo uppercase px-11 py-4 justify-center relative inline-flex overflow-hidden before:absolute before:top-0 before:left-0 before:z-[-1] before:w-[calc(100%+4px)] before:h-[calc(100%+4px)] before:content-[''] before:bg-[length:400%] after:absolute after:top-0 after:left-0 after:z-[-1] after:absolute after:w-full after:h-full after:content-[''] h-[56px] w-full" :href="route('dashboard')">Моят профил</Link>
                    <Link v-if="$page.props.auth.user.roles[0].name != 'Client'" class="text-white font-russo uppercase px-11 py-4 justify-center relative inline-flex overflow-hidden before:absolute before:top-0 before:left-0 before:z-[-1] before:w-[calc(100%+4px)] before:h-[calc(100%+4px)] before:content-[''] before:bg-[length:400%] after:absolute after:top-0 after:left-0 after:z-[-1] after:absolute after:w-full after:h-full after:content-[''] h-[56px] w-full" :href="route('admin-dashboard')">Админ панел</Link>
                    <!-- Authentication -->
                    <form @submit.prevent="logout">
                        <button class="text-white font-russo uppercase px-11 py-4 justify-center relative inline-flex overflow-hidden before:absolute before:top-0 before:left-0 before:z-[-1] before:w-[calc(100%+4px)] before:h-[calc(100%+4px)] before:content-[''] before:bg-[length:400%] after:absolute after:top-0 after:left-0 after:z-[-1] after:absolute after:w-full after:h-full after:content-[''] h-[56px] w-full">Изход</button>
                    </form>
                </li>
                <template v-else>
                    <li class="mb-4">
                        <Link class="text-white font-russo p-3" :href="route('login')">Вход</Link>
                    </li>
                    <li class="mb-4">
                        <Link class="glow-on-hover text-white font-russo uppercase px-11 py-4 justify-center relative inline-flex overflow-hidden before:absolute before:top-0 before:left-0 before:z-[-1] before:w-[calc(100%+4px)] before:h-[calc(100%+4px)] before:content-[''] before:bg-[length:400%] after:absolute after:top-0 after:left-0 after:z-[-1] after:absolute after:w-full after:h-full after:content-[''] h-[56px] w-full" :href="route('register')">Регистрирай се</Link>
                    </li>
                </template>
            </ul>
        </PerfectScrollbar>
    </aside>
    <div class="home_bg fixed h-screen w-full top-0 left-0 z-0 bg-[url('/assets/bg/home4BG.jpg')] bg-center bg-no-repeat bg-cover"></div>
    <div class="relative z-10">
        <canvas id="canvas" class="fixed z-10 w-full top-0 left-0"></canvas>
        <div class="relative z-30">
            <header id="gamfi-header" class="bg-transparent w-full py-6">
                <div class="">
                    <div class="container mx-auto">
                        <div class="flex justify-between items-center">
                            <Link :href="'/'">
                                <ApplicationLogo />
                            </Link>
                            <ul class="hidden md:block">
                                <li class="inline-block">
                                    <Link class="text-white font-russo p-3" :href="route('book')">Резервирай</Link>
                                </li>
                                <li class="inline-block">
                                    <Link class="text-white font-russo p-3" :href="route('gallery')">Галерия</Link>
                                </li>
                                <li class="inline-block">
                                    <Link class="text-white font-russo p-3" :href="route('faq')">ЧЗВ</Link>
                                </li>
                                <li class="inline-block">
                                    <Link class="text-white font-russo p-3" :href="route('leaderboards')">Класации</Link>
                                </li>
                                <li class="inline-block">
                                    <Link class="text-white font-russo p-3" :href="route('contacts')">Контакти</Link>
                                </li>
                            </ul>
                              
                            <div class="gamfi-btn-area hidden md:block">
                                <Link
                                    v-if="$page.props.auth.user"
                                    :href="route('dashboard')"
                                    class="glow-on-hover text-white font-russo uppercase px-11 py-4 justify-center relative inline-flex overflow-hidden before:absolute before:top-0 before:left-0 before:z-[-1] before:w-[calc(100%+4px)] before:h-[calc(100%+4px)] before:content-[''] before:bg-[length:400%] after:absolute after:top-0 after:left-0 after:z-[-1] after:absolute after:w-full after:h-full after:content-[''] h-[56px]"
                                    >Моят профил</Link
                                >

                                <Link
                                    v-if="$page.props.auth.user && $page.props.auth.user.roles[0].name != 'Client'"
                                    :href="route('admin-dashboard')"
                                    class="text-white font-russo uppercase px-11 py-4 justify-center relative inline-flex overflow-hidden before:absolute before:top-0 before:left-0 before:z-[-1] before:w-[calc(100%+4px)] before:h-[calc(100%+4px)] before:content-[''] before:bg-[length:400%] after:absolute after:top-0 after:left-0 after:z-[-1] after:absolute after:w-full after:h-full after:content-[''] h-[56px]"
                                    >Админ панел</Link
                                >

                                <template v-if="!$page.props.auth.user">
                                    <Link
                                        :href="route('login')"
                                        class="text-white font-russo uppercase px-11 py-4 justify-center relative inline-flex overflow-hidden before:absolute before:top-0 before:left-0 before:z-[-1] before:w-[calc(100%+4px)] before:h-[calc(100%+4px)] before:content-[''] before:bg-[length:400%] after:absolute after:top-0 after:left-0 after:z-[-1] after:absolute after:w-full after:h-full after:content-[''] h-[56px]"
                                        >Вход</Link
                                    >

                                    <Link
                                        :href="route('register')"
                                        class="glow-on-hover text-white font-russo uppercase px-11 py-4 justify-center relative inline-flex overflow-hidden before:absolute before:top-0 before:left-0 before:z-[-1] before:w-[calc(100%+4px)] before:h-[calc(100%+4px)] before:content-[''] before:bg-[length:400%] after:absolute after:top-0 after:left-0 after:z-[-1] after:absolute after:w-full after:h-full after:content-[''] h-[56px]"
                                        >Регистрирай се</Link
                                    >
                                </template>                          
                            </div>
                            <button @click="toggleMobileMenu" type="button" class="inline-flex items-center p-2 text-sm text-gray-500 md:hidden bg-primary hover:bg-white border-2 border-primary focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 transition-all">
                               <span class="sr-only">Open sidebar</span>
                               <svg class="w-7 h-7 fill-white" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                               </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <slot />
            <div class="container mx-auto pt-24">
                <div class="text-center mb-12">
                    <img class="inline-block mr-3" src="/assets/icons/steps2.png" alt="Steps-Image">
                    <span class="text-white uppercase font-russo">Последвай ни</span>
                    <img class="inline-block ml-3" src="/assets/icons/steps.png" alt="Steps-Image">
                </div>
                <div class="mb-20 text-center">
                    <ul class="footer-socials">
                        <li class="inline-block">
                            <a href="https://www.instagram.com/speedloungebg/" target="_blank" rel="nofollow" class="w-14 h-14 mx-3 bg-[url('/assets/icon-image.png')] flex items-center justify-center">
                                <v-icon name="bi-instagram" class="text-white w-6 h-6"/>
                            </a>
                        </li>
                        <li class="inline-block">
                            <a href="https://www.facebook.com/speedloungebg/" target="_blank" rel="nofollow" class="w-14 h-14 mx-3 bg-[url('/assets/icon-image.png')] flex items-center justify-center">
                                <v-icon name="fa-facebook-f" class="text-white w-6 h-6"/>
                            </a>
                        </li>
                        <li class="inline-block">
                            <a href="https://www.tiktok.com/@speedloungebg" target="_blank" rel="nofollow" class="w-14 h-14 mx-3 bg-[url('/assets/icon-image.png')] flex items-center justify-center">
                                <v-icon name="fa-tiktok" class="text-white w-6 h-6"/>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="mb-10 text-center">
                    <Link :href="'/'" class="inline-block">
                        <ApplicationLogo />
                    </Link>
                </div>
                <div class="text-center mb-5">
                    <ul>
                        <li class="inline-block px-3">
                            <Link class="text-white/70" :href="route('policy.show')">Лични данни</Link>
                        </li>
                        <li class="inline-block px-3">
                            <Link class="text-white/70" :href="route('terms.show')">Общи условия</Link>
                        </li>
                        <li class="inline-block px-3">
                            <Link class="text-white/70" :href="route('cookie-policy')">Политика за бисквитки</Link>
                        </li>
                    </ul>                        
                </div>
                <div class="text-center mb-9 text-white/70">
                    © {{ new Date().getFullYear() }} Speed Lounge Всички права запазени
                </div>
            </div>
        </div>
    </div>
</template>
